import { navigateToUrl } from 'single-spa';

export const APOLLO_SHELL_ID = 'apollo-shell';

export function setApShellAttribute(attribute: string, value: string) {
    const shell = document.getElementById(APOLLO_SHELL_ID);
    if (shell) {
        shell.setAttribute(attribute, value);
    } else {
        console.error(`Unable to find ap-shell element to set attribute ${attribute} with value ${value}`);
    }
}

function initializeShell() {
    const org = window.location.pathname.split('/')[1];

    // prevent initialize shell if no org name exists
    if (!org || org.endsWith('_')) {
        return;
    }

    (window as any).PortalShell.initialize({
        devProperties: {
            organizationStandardUrl: `${PORTAL_ORIGIN}`,
            organizationName: org.endsWith('_') ? undefined : org,
            mode: 'dev-cloud',
            isMFERoot: true,
        },
    });
}

export function createShell() {
    if (document.querySelector('ap-shell')) {
        return;
    }

    const shell = document.createElement('ap-shell');

    shell.id = APOLLO_SHELL_ID;
    shell.setAttribute('hideShell', 'true');

    document.querySelector('main').appendChild(shell);

    if ((window as any).PortalShell) {
        initializeShell();
    } else {
        document.addEventListener('portalShellLoaded', () => {
            initializeShell();
        });
    }

    document.addEventListener('serviceClicked', (event: CustomEvent<{ serviceid: string, url: string }>) => {
        setApShellAttribute('active', event.detail.serviceid);
        navigateToUrl(event.detail.url);
    });

}