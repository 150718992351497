import {
    getMountedApps,
    navigateToUrl,
} from 'single-spa';

import { createShell } from './PortalShell';

export function addRootContainerListeners() {
    window.addEventListener('single-spa:before-routing-event', (evt) => {
        document.getElementById('single-spa-error-container').innerHTML = ``;
    });

    window.addEventListener('single-spa:routing-event', (evt) => {
        // This is to handle the initial default page transition to portal_ service.
        // This is needed when we don't have an org or a service_ in the url.
        if (getMountedApps().length === 0) {
            const location = window.location;
            const parts = location.pathname.split('/').filter(Boolean);
            if (parts.length < 2) {
                navigateToUrl('/portal_');
            }
        }
    });
}

export function updateRootContainerGlobals() {
    const org = window.location.pathname.split('/')[1];
    const tenant = window.location.pathname.split('/')[2];

    const hasOrg = org && !org.endsWith('_');
    const hasTenant = hasOrg && tenant && !tenant.endsWith('_');

    (window as any).__ROOT_CONTAINER__ = {
        portalDomain: `${PORTAL_DOMAIN}`,
        org: hasOrg ? org : undefined,
        tenant: hasTenant ? tenant : undefined,
    };

    // Creates the `ap-shell` element
    createShell();
}
