export function setOverrides() {
    const currentUrl = new URL(window.location.href);
    const searchParams = currentUrl.searchParams;

    if (searchParams.get('spa-reset')) {
        (window as any).importMapOverrides.resetOverrides();
        localStorage.removeItem('devtools');
    }

    searchParams.getAll('spa-module').forEach(module => {
        const { name, path } = JSON.parse(module);

        (window as any).importMapOverrides.addOverride(name, path);
        localStorage.setItem('devtools', 'true');
    });
}